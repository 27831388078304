import dayjs from 'dayjs'
export default async function(context) {
  const { isHMR, app, store, route, redirect, query, isDev } = context
  if (isHMR) return
  const { knownLocales } = store.state.config
  const localeOnUrl = route.fullPath.split('/')[1].split('?')[0] || ''
  const locales = app.i18n.locales
  const CURRENT_LOCALE = app.i18n.locale

  const urlsWithoutLanguage = [
    '/cl/dl.php',
    '/cl/tngredirect2.php',
    '/cl/maybankredirect.php',
    '/mobile-apps-download',
    '/callback',
    '/redirect',
    '/redirect/dpl',
    '/email-review-banner-appstore'
  ]
  // langOnUrl is known -> found on locales ? ignore : replace to current locale
  // langOnUrl is unknown -> redirect with language

  const isLocaleOnUrlKnown = knownLocales.some(
    locale => `${localeOnUrl}`.toLowerCase() === locale.toLowerCase()
  )
  const isLocaleOnUrlActive = locales.some(
    locale => locale.code.toLowerCase() === localeOnUrl.toLowerCase()
  )
  const shouldReplaceLowerCase =
    isLocaleOnUrlKnown &&
    isLocaleOnUrlActive &&
    !locales.some(locale => locale.code === localeOnUrl)

  // exclude redirect for excluded url
  const isUrlExcluded = urlsWithoutLanguage.some(url => {
    return route.path.startsWith(url)
  })
  if (!isUrlExcluded) {
    if (!isLocaleOnUrlKnown) {
      return redirect(encodeURI(`/${CURRENT_LOCALE}${route.path}`), query)
    } else if (!isLocaleOnUrlActive) {
      return redirect(
        encodeURI(
          `/${CURRENT_LOCALE}${route.path.replace(
            `/${localeOnUrl.toLowerCase()}`,
            ''
          )}`
        ),
        query
      )
    } else if (shouldReplaceLowerCase) {
      return redirect(
        encodeURI(
          `/${localeOnUrl.toLowerCase()}${route.path.replace(
            `/${localeOnUrl}`,
            ''
          )}`
        ),
        query
      )
    }
    store.commit('config/SET_LANG', localeOnUrl)
    store.commit('config/SET_LIST_CURRENCY_LANG', localeOnUrl)
    await app.i18n.setLocale(localeOnUrl)
    app.$cookies.set('Language_code', localeOnUrl, {
      domain: !process.env.isDev ? '.airpaz.com' : '',
      path: '/',
      expires: dayjs()
        .add(10, 'year')
        .toDate()
    })
  }
}
