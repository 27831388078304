import { render, staticRenderFns } from "./error.vue?vue&type=template&id=7401f276&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=7401f276&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7401f276",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Error401: require('/builds/apzfront/forerunner/components/error/error401.vue').default,Error404: require('/builds/apzfront/forerunner/components/error/error404.vue').default,Error404Payment: require('/builds/apzfront/forerunner/components/error/error404Payment.vue').default,Error500: require('/builds/apzfront/forerunner/components/error/error500.vue').default})
