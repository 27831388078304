import {
  validateDate,
  validateDepartDate,
  validateReturnDate
} from '@/assets/js/globalFunction'

export default function({ app, query, redirect }) {
  const {
    depDate,
    retDate,
    depAirport,
    arrAirport,
    adult,
    child,
    infant
  } = query

  const isRoundTrip = !!retDate

  /** REDIRECT TO HOME */
  let isInvalid = false

  if (Object.values(query).length == 0) isInvalid = true

  // date
  if (!depDate && !validateDate(depDate)) isInvalid = true

  // departure and arrival airport
  if (!depAirport || !arrAirport) isInvalid = true
  else if (depAirport === arrAirport) isInvalid = true
  else {
    // multiairport
  }

  // passenger
  const totalPsg = +adult + +child + +infant

  if (+adult < 1 || totalPsg > 6 || infant > adult) isInvalid = true

  if (isInvalid) return redirect('/flight')

  /** REDIRECT WITH READJUSTED QUERY */
  const newQuery = Object.assign({}, query)
  const validatedDepDate = validateDepartDate(depDate)

  if (validatedDepDate !== depDate) {
    isInvalid = true
    newQuery.depDate = validatedDepDate
  }

  if (isRoundTrip) {
    const validatedRetDate = validateReturnDate(retDate, depDate)

    if (validatedRetDate !== retDate) {
      isInvalid = true
      newQuery.retDate = validatedRetDate
    }
  }

  if (isInvalid)
    return redirect(
      encodeURI(
        app.localePath({
          name: 'flight-search',
          query: newQuery
        })
      )
    )
}
