import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { isSet, isStringEqual } from '@/assets/js/globalFunction'

const defNull = o => (isSet(o) ? o : null)

export default function(ctx) {
  const { route, app, isDev } = ctx
  const params = route.query || {}
  const documentReferrer = process.server
    ? get(ctx, 'req.headers.referer', '')
    : document.referrer
  const mappedAds = mapAds(params, documentReferrer)
  const cookieExpired = 24 * 60 * 60 // 1day
  const mappedAft = mapAft(params)
  if (mappedAft) {
    const bufferAft = Buffer.from(JSON.stringify(mappedAft)).toString('base64')
    app.$cookies.set('affiliate_params', bufferAft, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      maxAge: cookieExpired
    })
  }

  if (app.$cookies.get('ads_traffic')) {
    return
  }

  if (mappedAds) {
    const bufferedAds = Buffer.from(JSON.stringify(mappedAds)).toString(
      'base64'
    )
    app.$cookies.set('ads_traffic', bufferedAds, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      maxAge: cookieExpired
    })
  }
}

function mapAds(params, referrer) {
  const adsProps = ['s', 'a', 'm', 'p', 'k', 'c', 'd', 'dm']
  let ads = {
    s: null,
    a: null,
    m: null,
    p: null,
    k: null,
    c: null,
    d: null,
    dm: null
  }

  if (isStringEqual(params.utm_source, 'bingads') || isSet(params.msclkid)) {
    ads = { ...ads, ...pick(params, adsProps) }
    ads.s = params.s || 'BINGADS'
  } else if (isStringEqual(params.utm_source, 'tiktokads')) {
    ads.a = 'TIKTOKADS'
  } else if (
    isStringEqual(params.source, 'YAHOO_JAPAN') ||
    isStringEqual(params.utm_source, 'yahoojpads')
  ) {
    ads.s = params.s || 'YHOOADSJP'
    ads.a = defNull(params.campaign)
    ads.k = defNull(params.keyword)
  } else if (isSet(params.gclid) && isSet(params.a)) {
    ads = { ...ads, ...pick(params, adsProps) }
    ads.s = params.s || 'GADS'
    ads.u = defNull(params.u)
    ads.l = defNull(params.l)

    if (isSet(params.u)) {
      const group = /(\:)?aud-(.*?)\:/g.exec(params.u) // get the suffix of aud-

      if (isSet(group) && group[2]) ads.u = group[2]
    }
  } else if (isSet(params.cid) && isSet(params.adsid) && isSet(params.aid)) {
    ads = { ...ads, ...pick(params, adsProps) }
    ads.s = 'FBADS'
    ads.a = defNull(params.adsid)
  } else {
    if (referrer.includes('facebook.com')) {
      ads.a = 'FB'
    } else if (referrer.includes('google')) {
      ads.a = 'GOOG'
    } else if (referrer.includes('goo.gl')) {
      ads.a = 'GURL'
    } else if (referrer.includes('bing')) {
      ads.a = 'BING'
    } else if (referrer.includes('naver')) {
      ads.a = 'NAVER'
    } else if (referrer.includes('yahoo.co.jp')) {
      ads.a = 'YHOOJP'
    } else if (referrer.includes('yahoo')) {
      ads.a = 'YHOO'
    } else if (referrer.includes('baidu')) {
      ads.a = 'BAIDU'
    } else if (referrer.includes('ow.ly')) {
      ads.a = 'HOOT'
    } else if (referrer.includes('oemarket')) {
      ads.a = 'MAIL'
    } else if (referrer.includes('youtube.com')) {
      ads.a = 'YOUTUBE'
    } else if (referrer.includes('blog.airpaz')) {
      ads.a = 'BLOG'
    } else if (referrer.includes('connect.airpaz')) {
      ads.a = 'MAIL'
    } else if (referrer.includes('/widget/search')) {
      ads.a = 'WIDGET'
    } else return false
  }

  return ads
}

function mapAft(params) {
  let clickId = ''
  const { aft, ...extras } = params

  if (!aft) return

  if (!isEmpty(extras)) {
    clickId = Object.entries(extras).reduce((str, extra, idx, arr) => {
      const [key, value] = extra
      if (key.includes('id')) str += `${value}`

      return str
    }, clickId)
  }

  return {
    id: aft,
    clickId
  }
}
