import axios from 'axios'
import pick from 'lodash/pick'
import consola from 'consola'

const ENV_PREFIX =
  process.env.APP_ENV === 'staging' || process.env.APP_ENV === 'development'
    ? 'DEV_'
    : ''
export default async function(req, res, next) {
  const hashedData = req.url.substring(1)
  let trxData = {}
  try {
    trxData = JSON.parse(Buffer.from(hashedData, 'base64').toString())
  } catch (err) {
    consola.error('Middleware::hotelReview >> Parse Object Hash Failed', err)
  }
  const { trxId, email, hash } = trxData
  try {
    const response = await axios.post(
      process.env[`${ENV_PREFIX}GALAXY_URL`] + '/book/get',
      { trxId: trxId, email: email }
    )
    if (!response || !response.data) {
      throw response
    }
    const order = response.data.result.orders[0]
    const rooms = order.rooms.map(room => room.name)
    const reviewData = {
      ...pick(order, [
        'hotelName',
        'city',
        'district',
        'country',
        'checkInDateTime',
        'checkOutDateTime',
        'photos'
      ]),
      rooms,
      hash
    }
    res.reviewData = reviewData
  } catch (err) {
    consola.log(err)
  } finally {
    next()
  }
}
