import { stringifyFlightTicketRoute } from '@/assets/js/globalFunction'

const flightTicketUrlFormatter = (path, { store }) => {
  if (!path) return ''
  try {
    const arrOfDetectedAirport = path.match(/\b[a-zA-Z]{3}\b/g)
    const arrOfAirport = arrOfDetectedAirport
      .map(suspectAirportCode => {
        const { cityName, cityNameEN } = store.getters[
          'master/getAirportDetail'
        ](suspectAirportCode)
        if (cityName) {
          return { cityName: cityNameEN || cityName, code: suspectAirportCode }
        }
      })
      .filter(item => !!item)
    if (arrOfAirport.length === 2) {
      return `flight-tickets/${stringifyFlightTicketRoute(
        arrOfAirport[0],
        arrOfAirport[1]
      )}`
    } else {
      return ''
    }
  } catch (_e) {
    return ''
  }
}

const redirectData = {
  support: {
    toUrl: 'help',
    redirectFullPath: false,
    isExactMatch: false
  },
  search: {
    toUrl: 'flight',
    redirectFullPath: false,
    isExactMatch: false
  },
  'tiket-pesawat/': {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  机票: {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  ตั๋วเครื่องบิน: {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  'tiket-penerbangan': {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  항공권: {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  航空券: {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  'Vé-máy-bay': {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  機票: {
    toUrl: flightTicketUrlFormatter,
    redirectFullPath: false,
    isExactMatch: false,
    statusCode: 301
  },
  'contact/feedback': {
    toUrl: 'contact',
    redirectFullPath: false,
    isExactMatch: false
  },
  'terms/covid19': {
    toUrl: 'covid19',
    redirectFullPath: true,
    isExactMatch: false
  },
  'precautions-covid-19': {
    toUrl: 'covid19',
    redirectFullPath: true,
    isExactMatch: false
  },
  'help/precautions-covid-19': {
    toUrl: 'covid19',
    redirectFullPath: true,
    isExactMatch: false
  },
  'guide/bookingflight': {
    toUrl: 'https://www.airpaz.com/en/guide/booking-flight',
    redirectFullPath: true,
    isExactMatch: true
  }
}
export default async function(context) {
  const { redirect, route } = context
  Object.keys(redirectData).forEach(urlToRedirect => {
    const URLWithoutLang = route.path
      .split('/')
      .slice(2)
      .join('/')
    const routeLang = route.path.split('/')[1]
    if (
      URLWithoutLang &&
      !redirectData[urlToRedirect].isExactMatch &&
      URLWithoutLang.startsWith(urlToRedirect)
    ) {
      let redirectUrl
      if (redirectData[urlToRedirect].redirectFullPath) {
        const newUrl =
          typeof redirectData[urlToRedirect].toUrl === 'function'
            ? redirectData[urlToRedirect].toUrl(route.fullPath, context)
            : redirectData[urlToRedirect].toUrl

        redirectUrl = route.fullPath.replace(urlToRedirect, newUrl)
      } else {
        const newUrl =
          typeof redirectData[urlToRedirect].toUrl === 'function'
            ? redirectData[urlToRedirect].toUrl(route.fullPath, context)
            : redirectData[urlToRedirect].toUrl
        redirectUrl = `/${routeLang}/${newUrl}`
      }
      return redirect(
        redirectData[urlToRedirect].statusCode || 302,
        encodeURI(redirectUrl)
      )
    }
  })
}
