import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import isMobileDevice from 'ismobilejs'

export default async function(context) {
  const { req, store, app, isDev, redirect, route } = context
  const userAgent = context.req
    ? req.headers['user-agent']
    : navigator.userAgent

  context.isMobile =
    isMobileDevice(userAgent).any || /apzIos|apzAndroid/g.test(userAgent)
  context.store.commit(
    'app/updateBrowserType',
    context.isMobile ? 'mobile' : 'desktop'
  )

  // SET ANONYMOUS UUID
  const detectedAUID = app.$cookies.get('auid') || context.userAUID
  if (!detectedAUID) {
    const generatedAUID = uuidv4()
    context.userAUID = generatedAUID
    app.$cookies.set('auid', generatedAUID, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      expires: dayjs()
        .add(10, 'year')
        .toDate()
    })
  }

  if (app.$auth && app.$auth.loggedIn) {
    const routeName = app.getRouteBaseName(route)
    if (routeName === 'order-index' || routeName === 'order-index-id') {
      const path = route.fullPath.replace('/order', '/account/order')
      return redirect(encodeURI(path))
    }
  }
  //User API
  if (process.server && !isDev) {
    const ip = req.connection.remoteAddress || req.socket.remoteAddress
    store.commit('config/SET_IP', ip)
  }
}
