//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import error401 from '~/components/error/error401'
import error404 from '~/components/error/error404'
import error500 from '~/components/error/error500'
import error404Payment from '~/components/error/error404Payment'

import { mapState, mapGetters } from 'vuex'
import { getBufferString } from '~/assets/js/globalFunction'

export default {
  components: {
    error401,
    error404,
    error500,
    error404Payment
  },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      hasSubtitle: 'header/hasSubtitle'
    }),
    ...mapState({
      hasSubtitle: state => state.header.hasSubtitle,
      isBookFailed: state => state.payment.isGetBookDataFailed
    }),
    isMobile() {
      return this.$store.state.app.browserType === 'mobile'
    },

    isDesktop() {
      return this.$store.state.app.browserType === 'desktop'
    },

    containerId() {
      return this.isMobile ? 'airpaz-mobile' : 'airpaz-desktop'
    },
    errorId() {
      return this.$cookies.getAll()['err-msg-id']
    },
    errorBookResponse() {
      const bookErrorList = [
        {
          id: 'TRX_MEMBER_NOT_FOUND',
          title: this.$t('result.ordernofound'),
          subtitle: this.$t('result.ordernofounddesc')
        },
        {
          id: 'TRX_NOT_FOUND',
          title: this.$t('result.ordernofound'),
          subtitle: this.$t('result.ordernofounddesc')
        }
      ]

      return bookErrorList.find(error => error.id == this.error.error)
    }
  },
  methods: {
    async onClickRetry() {
      const params = {
        email: getBufferString(this.$route.query.h),
        trxId: this.$route.params.id
      }
      await this.$store.dispatch('payment/getBook', params)
    }
  }
}
