import {
  validateCheckInDate,
  validateCheckOutDate
} from '@/assets/js/globalFunction.js'
import hotelConfig from '@/config/hotel-defaults.js'

export default async function(context) {
  const { app } = context
  const { query, params } = context.route
  if (Object.values(query).length === 0) {
    return context.redirect(`/${app.i18n.locale}/hotel`)
  }
  if (!query.id || !query.d || !query.t) {
    return context.redirect(`/${app.i18n.locale}/hotel`)
  }

  let needRedirect = false
  const newCheckIn = validateCheckInDate(query.ci)
  const newCheckOut = validateCheckOutDate(query.co, newCheckIn)

  if (query.ci !== newCheckIn) {
    query.ci = newCheckIn
    needRedirect = true
  }

  if (query.co !== newCheckOut) {
    query.co = newCheckOut
    needRedirect = true
  }

  if (!query.co) {
    query.co = new Date(query.ci)
    query.co = query.co.setDate(query.co.getDate() + 1)
    needRedirect = true
  }
  if (!query.ro) {
    query.ro = hotelConfig.defaultRoom
    needRedirect = true
  }

  if (!query.ad) {
    query.ad = hotelConfig.defaultAdult
    needRedirect = true
  }

  if (!!query.mx && query.mx == 0) {
    delete query.mx
  }

  if (
    !!query.so &&
    !hotelConfig.defaultSortItems.find(item => item.id === query.so)
  ) {
    if (query.so === 'smart_save' && !!query.ss) return

    query.so = hotelConfig.defaultSort
    needRedirect = true
  }

  if (query.ss && query.so !== 'smart_save') delete query.ss

  if (needRedirect) {
    return context.redirect({
      path: app.localePath({ path: `/hotel/search` }),
      query
    })
  }
}
